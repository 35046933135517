var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "output-pills" },
    [
      _vm._l(_vm.loading ? [] : _vm.slides, function(slide, idx) {
        return _c(
          "div",
          {
            key: "output-pills-pill-" + idx,
            staticClass: "output-pills-pill-wrapper"
          },
          [
            _c("div", {
              staticClass: "output-pills-pill",
              class: {
                dynamic: slide.dynamic,
                selected: _vm.selectedIdx === idx && _vm.selectedBlockIdx === -1
              },
              on: {
                click: function() {
                  return _vm.$emit("select", idx)
                }
              }
            }),
            _vm.slideBlockPreviews[idx].length
              ? _c(
                  "div",
                  { staticClass: "output-pills-pill-block" },
                  _vm._l(_vm.slideBlockPreviews[idx], function(
                    insertedSlide,
                    blockIdx
                  ) {
                    return _c("div", {
                      key: "output-pills-pill-block-" + blockIdx,
                      staticClass: "output-pills-pill inserted",
                      class: {
                        selected:
                          _vm.selectedIdx === idx &&
                          _vm.selectedBlockIdx === blockIdx
                      },
                      on: {
                        click: function() {
                          return _vm.$emit("select", idx, blockIdx)
                        }
                      }
                    })
                  }),
                  0
                )
              : _vm._e()
          ]
        )
      }),
      _vm._l(
        _vm.loading || !(_vm.slides && _vm.slides.length)
          ? [1, 2, 3, 4, 5, 6, 7]
          : [],
        function(s) {
          return _c("b-skeleton", {
            key: "output-pills-pill-" + s,
            staticClass: "output-pills-pill"
          })
        }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }