var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "presentation-preview" }, [
    _c(
      "div",
      { staticClass: "presentation-preview-slide" },
      [
        _vm.showContent
          ? _c(
              "div",
              {
                staticClass: "presentation-preview-slide-preview",
                class: {
                  "full-height": !_vm.selectedSlideDynamic || _vm.hideRegenerate
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "presentation-preview-slide-preview-img-wrapper"
                  },
                  [
                    _c("img", {
                      staticClass: "presentation-preview-slide-preview-img",
                      attrs: { src: _vm.selectedSlide.preview, alt: "" }
                    })
                  ]
                )
              ]
            )
          : _c("b-skeleton"),
        _c(
          "div",
          {
            staticClass: "presentation-preview-slide-prompt",
            class: { visible: _vm.selectedSlideDynamic && !_vm.hideRegenerate }
          },
          [
            _c("TextInput", {
              staticClass: "presentation-preview-slide-prompt-input",
              attrs: {
                placeholder: _vm.promptPlaceholder,
                "max-length": 200,
                disabled: !_vm.showContent
              },
              on: { submit: _vm.generateOutput },
              model: {
                value: _vm.prompt,
                callback: function($$v) {
                  _vm.prompt = $$v
                },
                expression: "prompt"
              }
            }),
            _vm.selectedSlideDynamic
              ? _c("Button", {
                  attrs: {
                    text: "Regenerate",
                    icon: "ai",
                    size: "xs",
                    loading: _vm.loading
                  },
                  on: { click: _vm.generateOutput }
                })
              : _vm._e(),
            _vm.selectedSlide && _vm.selectedSlide.prompt
              ? _c("Button", {
                  attrs: {
                    text: "Reset",
                    size: "xs",
                    type: "white",
                    loading: _vm.loading
                  },
                  on: { click: _vm.resetOutput }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "presentation-preview-carousel" },
      [
        _c("PresentationOutputCarousel", {
          ref: "outputcarousel",
          attrs: {
            slides: _vm.slides,
            blocks: _vm.blocks,
            "selected-idx": _vm.selectedSlideIdx,
            "selected-block-idx": _vm.selectedBlockIdx,
            loading: _vm.loading,
            "slide-regenerating": _vm.slideRegenerating,
            "slide-block-previews": _vm.slideBlockPreviews
          },
          on: {
            select: _vm.selectSlide,
            next: _vm.selectNext,
            previous: _vm.selectPrevious
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "presentation-preview-pills" },
      [
        _c("PresentationOutputPills", {
          attrs: {
            slides: _vm.slides,
            "slide-block-previews": _vm.slideBlockPreviews,
            "selected-idx": _vm.selectedSlideIdx,
            "selected-block-idx": _vm.selectedBlockIdx,
            loading: _vm.loading
          },
          on: { select: _vm.selectSlide }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }